import moment from "moment";
import fetchMethodRequest from "./service";
import config from "./config";

export const getAmenities = async () => {
    try {
        const response = await fetchMethodRequest('GET', 'settings/getAmenities');
        if (response && response.respCode) {
            return response.amenities;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const updateCkEditorValues = (values) => {
    values = values?.replace(/-/g, '');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = values;
    return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));
};

export const dateAndTimeFormat = (timestamp, tableItem) => {
    if (timestamp) {
        const localDate = moment.utc(timestamp).local();
        let hours = localDate.hours();
        let minutes = localDate.minutes().toString().padStart(2, '0');
        let period = 'AM';

        if (hours >= 12) {
            period = 'PM';

            // Convert 12-hour format to 12 AM/PM format
            if (hours > 12) {
                hours -= 12;
            }
        }
        // Format the time as "hh:mm AM/PM"
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
        const formattedDate = `${localDate.format(config.dateDayMonthFormat)}`
        if (tableItem?.fieldUse === "dateAndTime") {
            return <span style={tableItem.style} title={formattedTime}>
                {formattedDate + ' ' + formattedTime}
            </span>;
        } else {
            return <span style={tableItem?.style} title={formattedTime}>
                {formattedTime}
            </span>;
        }
    }
}

export const PropertyAreaOptions = [
    {
        label: "Sq.Ft.",
        value: "sqft",
        color: "success",
    },
    {
        label: "Sq.Yards",
        value: "sqyards",
        color: "danger",
    },
    {
        label: "Sq.M.",
        value: "sqm",
        color: "warning",
    },
    {
        label: "Aankadam",
        value: "aankadam",
        color: "secondary",
    },
    {
        label: "Guntha",
        value: "guntha",
        color: "success",
    },
    {
        label: "Cents",
        value: "cents",
        color: "danger",
    },
    {
        label: "Acres",
        value: "acres",
        color: "info",
    },
    {
        label: "Hectares",
        value: "hectares",
        color: "dark",
    },
    {
        label: "Grounds",
        value: "grounds",
        color: "info",
    },
    {
        label: "Kanal",
        value: "kanal",
        color: "success",
    },
    {
        label: "Rood",
        value: "rood",
        color: "primary",
    },
    {
        label: "Chataks",
        value: "chataks",
        color: "dark",
    },
    {
        label: "Perch",
        value: "perch",
        color: "light",
    },
    {
        label: "Ares",
        value: "ares",
        color: "danger",
    },
    {
        label: "Biswa",
        value: "biswa",
        color: "warning",
    },
    {
        label: "Bigha",
        value: "bigha",
        color: "secondary",
    },
    {
        label: "Kottah",
        value: "kottah",
        color: "primary",
    },
    {
        label: "Marla",
        value: "marla",
        color: "light",
    },
];

const getClientFormFields = () => {
    return [
        {
            name: "firstName",
            fieldName: "firstName",
            type: "text",
            placeholder: "First Name",
            value: "",
            label: "First Name",
            width: "120px",
            addFormOrder: 2,
            editFormOrder: 1,
            stringType: null,
            derivedValue: "firstName=firstName=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "firstName",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            fieldType: "Link",
            style: {
                color: "#0e4768",
                cursor: "pointer",
                textTransform: "capitalize",
            },
            isAddFormHidden: false,
            isEditFormHidden: false,
            capitalizeValue: true
        },
        {
            name: "lastName",
            fieldName: "lastName",
            type: "text",
            placeholder: "Last Name",
            value: "",
            label: "Last Name",
            width: "120px",
            addFormOrder: 3,
            editFormOrder: 2,
            stringType: null,
            derivedValue: "lastName=lastName=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "lastName",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "email",
            fieldName: "email",
            type: "email",
            placeholder: "Email",
            value: "",
            label: "Email",
            width: "120px",
            addFormOrder: 4,
            editFormOrder: 3,
            stringType: null,
            derivedValue: "email=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "email",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "true",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "phoneNumber",
            fieldName: "phoneNumber",
            type: "text",
            placeholder: "Phone Number",
            value: "",
            label: "Phone Number",
            width: "120px",
            addFormOrder: 5,
            editFormOrder: 4,
            stringType: null,
            derivedValue: "phoneNumber=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "phoneNumber",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "true",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "type",
            fieldName: "type",
            type: "checkbox",
            placeholder: "Type",
            value: ['Seller'],
            label: "Type",
            width: "120px",
            addFormOrder: 7,
            editFormOrder: 7,
            stringType: null,
            derivedValue: "type=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: true,
            id: "type",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: "",
            displayinlist: "true",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: "",
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            options: [
                { label: 'Buyer', value: 'Buyer' },
                { label: 'Seller', value: 'Seller' },
            ],
            multiple: true,
            multipleSelect: false,
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
            optionsWidth: 'col-sm-4'
        },
        {
            name: "notes",
            fieldName: "notes",
            type: "textarea",
            placeholder: "Notes",
            value: "",
            label: "Notes",
            width: "120px",
            addFormOrder: 6,
            editFormOrder: 5,
            stringType: null,
            derivedValue: "notes=notes=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: false,
            id: "notes",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "photo",
            fieldName: "photo",
            type: "fileUpload",
            placeholder: "Photo",
            value: "",
            label: "Photo",
            width: "120px",
            addFormOrder: 8,
            editFormOrder: 6,
            stringType: null,
            derivedValue: "photo=photo=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: false,
            id: "photo",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: "",
            isBulkUploadField: false,
            fieldType: "fileUpload",
            fileType: "img/video",
            maxFileSize: 1,
            isMultipleRequired: false,
            imagePath: "Clients",
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "name",
            fieldName: "name",
            type: "text",
            placeholder: "Name",
            value: "",
            label: "Name",
            width: 110,
            addFormOrder: 1,
            editFormOrder: 10,
            derivedValue: "name=name=name=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: false,
            id: "name",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "createdByName",
            fieldName: "createdByName",
            type: "text",
            placeholder: "Created By",
            value: "",
            label: "Created By",
            width: 120,
            addFormOrder: 10,
            editFormOrder: 10,
            derivedValue: "createdByName=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: true,
            id: "createdByName",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: "employee",
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "updatedByName",
            fieldName: "updatedByName",
            type: "text",
            placeholder: "Updated By",
            value: "",
            label: "Updated By",
            width: 120,
            addFormOrder: 9,
            editFormOrder: 10,
            derivedValue: "updatedByName=updatedByName=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: true,
            id: "updatedByName",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "updated",
            fieldName: "updated",
            type: "date",
            placeholder: "Updated",
            value: "",
            label: "Updated",
            width: 90,
            addFormOrder: 10,
            editFormOrder: 10,
            derivedValue: "updated=updated=updated=undefined",
            capitalizeTableText: "",
            sortable: "",
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "updated",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            fieldType: "Date",
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "created",
            fieldName: "created",
            type: "date",
            placeholder: "Created",
            value: "",
            label: "Created",
            width: 90,
            addFormOrder: 10,
            editFormOrder: 10,
            derivedValue: "created=created=undefined",
            capitalizeTableText: "",
            sortable: "",
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "created",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            fieldType: "Date",
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
    ];
}

export default getClientFormFields;